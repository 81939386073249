<template>
  <div class="setUp" :style="{ height: infoHei }">
    <div class="cont-main col999">
      <div class="updateBox flex-start">
        <div class="setUp-left">
          <el-input
            @input="getDataList"
            v-model="searchSetUp.search_code"
            placeholder="搜索套组"
          >
            <template #suffix><i class="fa fa-search"></i></template>
          </el-input>
          <div class="scheme-cont">
            <el-scrollbar>
              <div
                class="item"
                v-for="item in list"
                :key="item.id"
                @click="setUpBut(item)"
                :class="item.id === actSetUpData.id ? 'active' : ''"
              >
                <div class="lable flex-between">
                  <div class="name ellipsis">{{ item.group_name }}</div>
                  <div class="ion">
                    <i
                      class="fa fa-edit"
                      @click.prevent.stop="setUpEdit(item)"
                    ></i>
                    <i
                      class="fa fa-trash-o"
                      @click.prevent.stop="setUpDel(item)"
                    ></i>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="setUp-table">
          <div class="type flex-between">
            <div class="table-top">
              <el-button class="color-vice" type="primary" @click="addSetups">
                <i class="fa fa-plus-circle"></i>新增套组
              </el-button>
            </div>
            <div class="upButtom">
              <el-button
                class="color-vice"
                type="primary"
                @click="openWeight('addLongIerm', '3')"
              >
                <i class="fa fa-plus-circle"></i>新增组套项目
              </el-button>
            </div>
          </div>
          <div class="tables">
            <el-table
              v-loading="tableLoading"
              :data="tableData"
              border
              size="small"
              max-height="400"
            >
              <el-table-column
                width="90"
                prop="dict_advice_type_name"
                label="类别"
              />
              <el-table-column prop="advice_content" label="医嘱内容" />
              <el-table-column width="90" label="透中用药">
                <template #default="scope">
                  {{ scope.row.drug_type === 1 ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column width="90" label="个人库存">
                <template #default="scope">
                  {{ scope.row.self_provided === 1 ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column width="90" label="院外用药">
                <template #default="scope">
                  {{ scope.row.is_preopen === 1 ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="modParient(scope.row)"
                  >
                    <i class="txIons modify"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="delParient(scope.row)"
                  >
                    <i class="txIons del"></i>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <BaseDialog
      :title="addSetup.id ? '修改组套' : '新增组套'"
      :isshow="visible"
      @handleShow="handleShow"
      width="600px"
      top="20%"
    >
      <div class="tongluBox">
        <el-form
          ref="addSetupRef"
          :model="addSetup"
          label-width="110px"
          label-position="right"
          class="demo-ruleForm col-333"
          :rules="Rule.SETUP"
        >
          <el-form-item label="新增组套:" prop="group_name">
            <el-input v-model="addSetup.group_name" placeholder="请输入名称" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="accessItem"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <addLongIerm
      v-if="isAddLongIerm"
      ref="addLongIermRef"
      :patientId="patientId"
      @refreshDataList="getAdviceGroupList"
    ></addLongIerm>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, nextTick } from 'vue'
import { getConstHei, clone } from '@/utils/tool.js'
import { useRoute } from 'vue-router'
import addLongIerm from './addLongIerm.vue'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import Rule from '@/utils/rule'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  name: 'setUp',
  components: {
    addLongIerm,
    BaseDialog,
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      ruleForm: {},
      infoHei: '',
      searchData: {
        status: '1',
        type: '1',
      },
      Rule: Rule,
      searchSetUp: {},
      addSetup: {
        group_name: '',
      },
      actSetUpData: {},
      list: [],
      tableData: [],
      isBatchLongIerm: false,
      batchLongIermRef: null,
      isAddLongIerm: false,
      addLongIermRef: null,
      saveLoading: false,
      visible: false,
      addSetupRef: null,
      tableLoading: false,
      patientId: route.params.id,
    })

    onMounted(() => {
      state.infoHei = getConstHei()
      initData()
    })

    const initData = async () => {
      await getDataList()
      if (state.list.length > 0) {
        if (!state.actSetUpData.id) {
          state.actSetUpData = state.list[0]
        }
        getAdviceGroupList()
      }
    }

    const getDataList = async () => {
      let res = await service.post('/api/advice_group/list', state.searchSetUp)
      if (res.code === 0) {
        state.list = res.data.records
      }
    }

    const setUpBut = (item) => {
      state.actSetUpData = item
      getAdviceGroupList()
    }
    const getAdviceGroupList = async () => {
      state.tableLoading = true
      let res = await service.post('/api/advice_group_item/list', {
        group_id: state.actSetUpData.id,
      })
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data
      }
    }

    const addSetups = () => {
      state.visible = true
      state.addSetup = {
        group_name: '',
      }
    }
    const setUpEdit = (item) => {
      state.addSetup = clone(item)
      state.visible = true
    }
    const setUpDel = (item) => {
      ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/advice_group/delete', {
            id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            if (item.id === state.actSetUpData.id) {
              state.actSetUpData = {}
              initData()
            } else {
              getDataList()
            }
          }
        })
        .catch(() => {})
    }
    const accessItem = () => {
      state.addSetupRef.validate(async (valid) => {
        if (valid) {
          state.saveLoading = true
          let url = ''
          if (state.addSetup.id) {
            url = '/api/advice_group/edit'
          } else {
            url = '/api/advice_group/create'
          }
          let res = await service.post(url, state.addSetup)
          state.saveLoading = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.visible = false
            getDataList()
          }
        }
      })
    }

    const openWeight = (type, val) => {
      if (!state.actSetUpData.id) return
      switch (type) {
        case 'batchLongIerm':
          state.isBatchLongIerm = true
          nextTick(() => {
            state.batchLongIermRef.initData()
          })
          break
        case 'addLongIerm':
          state.isAddLongIerm = true
          nextTick(() => {
            state.addLongIermRef.initData('', val, state.actSetUpData)
          })
          break
      }
    }
    const modParient = (item) => {
      state.isAddLongIerm = true
      nextTick(() => {
        state.addLongIermRef.initData(item, '3')
      })
    }
    const delParient = (item) => {
      ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/advice_group_item/delete', {
            id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getAdviceGroupList()
          }
        })
        .catch(() => {})
    }

    const handleShow = (val) => {
      state.visible = val
    }

    return {
      ...toRefs(state),
      handleShow,
      openWeight,
      addSetups,
      setUpDel,
      setUpEdit,
      accessItem,
      setUpBut,
      getDataList,
      getAdviceGroupList,
      modParient,
      delParient,
    }
  },
}
</script>

<style scoped lang="scss">
.setUp {
  margin-top: 10px;
}
.podBot {
  padding-bottom: 30px;
}
.setUp-left {
  width: 280px;
  margin-right: 16px;
}

.setUp-table {
  flex: 1;
}
.tables {
  margin-top: 20px;
}
.parient-tag {
  .el-tag {
    margin-right: 10px;
  }
}
.scheme-cont {
  padding: 0 10px;
  margin-top: 20px;
  border-right: 1px solid #e0e0e0;
  height: 500px;
  .item {
    height: 32px;
    line-height: 32px;
    width: 244px;
    font-size: 14px;
    font-family: 'Source Han Sans CN-Medium';
    color: #444444;
    padding-right: 14px;
    margin-bottom: 12px;
    .lable {
      background-color: #ffffff;
      border-radius: 8px 8px 8px 8px;
      padding-left: 20px;
      cursor: pointer;
      .ion {
        font-size: 16px;
        display: none;
        .fa {
          margin-right: 10px;
        }
      }
      &:hover {
        .ion {
          display: block;
        }
      }
    }
    &.active {
      border-right: 4px solid #3166ae;
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
    &:hover {
      .lable {
        background-color: rgba(49, 102, 174, 0.3);
        color: #3166ae;
      }
    }
  }
}
.tongluBox {
  margin-top: 20px;
}
</style>
